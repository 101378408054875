<template>
  <div>
    <div class="bpr-facetoface">
      <div class="bpr-facetoface__header">
        <div class="line-separator"></div>
        <div class="bpr-facetoface__header__title">
          ¿Cómo han sido la mayoría de los goles?
        </div>
      </div>
      <div class="bpr-facetoface__filterPlay">
        <div class="center-item"><RadioPlayingAs :is-mobile="isMobile" :section="'mayoria de goles'" /></div>
      </div>
      <div class="is-desktop">
        <div class="bpr-facetoface__table">
          <div class="bpr-facetoface__table__home">
            <div class="bpr-facetoface__table__home__header">
              <div class="bpr-facetoface__itemheadertable ">
                <div class="bpr-facetoface__itemheadertable__shield ">
                  <template v-if="gameData.homeTeam">
                    <img :src="gameData.homeTeam.logo" width="50" />
                  </template>
                </div>
                <div class="bpr-facetoface__itemheadertable__name margin-item-left paddingText-left">
                  <template v-if="gameData.homeTeam">
                    {{ gameData.homeTeam.colloquial_name }}
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="bpr-facetoface__table__away">
            <div class="bpr-facetoface__table__away__header">
              <div class="bpr-facetoface__itemheadertable">
                <div class="bpr-facetoface__itemheadertable__name margin-item-right paddingText-right">
                  <template v-if="gameData.awayTeam">
                    {{ gameData.awayTeam.colloquial_name }}
                  </template>
                </div>
                <div class="bpr-facetoface__itemheadertable__shield">
                  <template v-if="gameData.awayTeam">
                    <img :src="gameData.awayTeam.logo" width="50" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-facetoface__containerfield">
          <div class="bpr-facetoface__itemfields">
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title green">Anotados</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginHomeF.length > 0">
                    <template v-if="getOriginHomeF[0]">
                      <div
                        v-if="getOriginHomeF[0].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeF[1]">
                      <div
                        v-if="getOriginHomeF[1].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeF[2]">
                      <div
                        v-if="getOriginHomeF[2].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginHomeF.length > 0">
                  <template v-if="getOriginHomeF[0]">
                    <img
                      v-if="getOriginHomeF[0].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeF[1]">
                    <img
                      v-if="getOriginHomeF[1].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeF[2]">
                    <img
                      v-if="getOriginHomeF[2].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title red">Recibidos</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field_o" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginHomeC.length > 0">
                    <template v-if="getOriginHomeC[0]">
                      <div
                        v-if="getOriginHomeC[0].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeC[1]">
                      <div
                        v-if="getOriginHomeC[1].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeC[2]">
                      <div
                        v-if="getOriginHomeC[2].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginHomeC.length > 0">
                  <template v-if="getOriginHomeC[0]">
                    <img
                      v-if="getOriginHomeC[0].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeC[1]">
                    <img
                      v-if="getOriginHomeC[1].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeC[2]">
                    <img
                      v-if="getOriginHomeC[2].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div class="bpr-facetoface__itemfields">
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title green">Anotados</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginAwayF.length > 0">
                    <template v-if="getOriginAwayF[0]">
                      <div
                        v-if="getOriginAwayF[0].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayF[1]">
                      <div
                        v-if="getOriginAwayF[1].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayF[2]">
                      <div
                        v-if="getOriginAwayF[2].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginAwayF.length > 0">
                  <template v-if="getOriginAwayF[0]">
                    <img
                      v-if="getOriginAwayF[0].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayF[1]">
                    <img
                      v-if="getOriginAwayF[1].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayF[2]">
                    <img
                      v-if="getOriginAwayF[2].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title red">Recibidos</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <div class="cont_item_category">
                  <template v-if="getOriginAwayC.length > 0">
                    <template v-if="getOriginAwayC[0]">
                      <div
                        v-if="getOriginAwayC[0].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayC[1]">
                      <div
                        v-if="getOriginAwayC[1].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayC[2]">
                      <div
                        v-if="getOriginAwayC[2].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <img class="img_field" src="/images/cancha.png" width="192" />
                <template v-if="getOriginAwayC.length > 0">
                  <template v-if="getOriginAwayC[0]">
                    <img
                      v-if="getOriginAwayC[0].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayC[1]">
                    <img
                      v-if="getOriginAwayC[1].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayC[2]">
                    <img
                      v-if="getOriginAwayC[2].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <LegendLastSeason :mobile="isMobile" />
      </div>
      <div class="is-movil">
        <div class="bpr-facetoface__table">
          <div class="bpr-facetoface__table__home">
            <div class="bpr-facetoface__table__home__header">
              <div class="bpr-facetoface__itemheadertable float-item-left">
                <div class="bpr-facetoface__itemheadertable__shield ">
                  <template v-if="gameData.homeTeam">
                    <img :src="gameData.homeTeam.logo" width="50" />
                  </template>
                </div>
                <div class="bpr-facetoface__itemheadertable__name margin-item-left textaling-item-left">
                  <template v-if="gameData.homeTeam">
                    {{ gameData.homeTeam.colloquial_name }}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-facetoface__containerfield">
          <div class="bpr-facetoface__itemfields">
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title green">Anotados</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginHomeF.length > 0">
                    <template v-if="getOriginHomeF[0]">
                      <div
                        v-if="getOriginHomeF[0].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[0].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeF[1]">
                      <div
                        v-if="getOriginHomeF[1].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[1].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeF[2]">
                      <div
                        v-if="getOriginHomeF[2].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeF[2].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginHomeF.length > 0">
                  <template v-if="getOriginHomeF[0]">
                    <img
                      v-if="getOriginHomeF[0].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[0].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeF[1]">
                    <img
                      v-if="getOriginHomeF[1].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[1].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeF[2]">
                    <img
                      v-if="getOriginHomeF[2].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeF[2].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title red">Recibidos</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginHomeC.length > 0">
                    <template v-if="getOriginHomeC[0]">
                      <div
                        v-if="getOriginHomeC[0].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[0].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeC[1]">
                      <div
                        v-if="getOriginHomeC[1].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[1].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginHomeC[2]">
                      <div
                        v-if="getOriginHomeC[2].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginHomeC[2].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginHomeC.length > 0">
                  <template v-if="getOriginHomeC[0]">
                    <img
                      v-if="getOriginHomeC[0].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[0].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeC[1]">
                    <img
                      v-if="getOriginHomeC[1].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[1].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginHomeC[2]">
                    <img
                      v-if="getOriginHomeC[2].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginHomeC[2].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-facetoface__table">
          <div class="bpr-facetoface__table__home">
            <div class="bpr-facetoface__table__home__header">
              <div class="bpr-facetoface__itemheadertable float-item-left">
                <div class="bpr-facetoface__itemheadertable__shield ">
                  <template v-if="gameData.awayTeam">
                    <img :src="gameData.awayTeam.logo" width="50" />
                  </template>
                </div>
                <div class="bpr-facetoface__itemheadertable__name margin-item-left textaling-item-left">
                  <template v-if="gameData.awayTeam">
                    {{ gameData.awayTeam.colloquial_name }}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-facetoface__containerfield">
          <div class="bpr-facetoface__itemfields">
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title green">Anotados</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <img class="img_field" src="/images/cancha.png" width="192" />
                <div class="cont_item_category">
                  <template v-if="getOriginAwayF.length > 0">
                    <template v-if="getOriginAwayF[0]">
                      <div
                        v-if="getOriginAwayF[0].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[0].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayF[1]">
                      <div
                        v-if="getOriginAwayF[1].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[1].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayF[2]">
                      <div
                        v-if="getOriginAwayF[2].category == 21"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 22"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 23"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 24"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 25"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 26"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 27"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 28"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 29"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 30"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayF[2].category == 31"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <template v-if="getOriginAwayF.length > 0">
                  <template v-if="getOriginAwayF[0]">
                    <img
                      v-if="getOriginAwayF[0].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[0].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayF[1]">
                    <img
                      v-if="getOriginAwayF[1].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[1].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayF[2]">
                    <img
                      v-if="getOriginAwayF[2].category == 21"
                      data-id="21"
                      class="img_field"
                      src="/images/origenes/origen_centro_izq.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 22"
                      data-id="22"
                      class="img_field"
                      src="/images/origenes/origen_centro_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 23"
                      data-id="23"
                      class="img_field"
                      src="/images/origenes/origen_corner_izquierda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 24"
                      data-id="24"
                      class="img_field"
                      src="/images/origenes/origen_corner_derecha.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 25"
                      data-id="25"
                      class="img_field"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 26"
                      data-id="26"
                      class="img_field"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 27"
                      data-id="27"
                      class="img_field"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 28"
                      data-id="28"
                      class="img_field"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 29"
                      data-id="29"
                      class="img_field"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 30"
                      data-id="30"
                      class="img_field"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayF[2].category == 31"
                      data-id="31"
                      class="img_field"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
            <div class="bpr-facetoface__itemfields__container">
              <div class="bpr-facetoface__itemfields__container__title red">Recibidos</div>
              <div class="bpr-facetoface__itemfields__container__field">
                <div class="cont_item_category">
                  <template v-if="getOriginAwayC.length > 0">
                    <template v-if="getOriginAwayC[0]">
                      <div
                        v-if="getOriginAwayC[0].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[0].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayC[1]">
                      <div
                        v-if="getOriginAwayC[1].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[1].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                    <template v-if="getOriginAwayC[2]">
                      <div
                        v-if="getOriginAwayC[2].category == 33"
                        data-id="21"
                        class="item_category"
                        style="background-color: #00a5ff;"
                      >
                        Centro por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 32"
                        data-id="22"
                        class="item_category"
                        style="background-color: #70c10a;"
                      >
                        Centro por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 35"
                        data-id="23"
                        class="item_category"
                        style="background-color: #8f00ff;"
                      >
                        Corners por izquierda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 34"
                        data-id="24"
                        class="item_category"
                        style="background-color: #eb00ff;"
                      >
                        Corners por derecha
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 36"
                        data-id="25"
                        class="item_category"
                        style="background-color: #ff9900;"
                      >
                        Pase Filtrado
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 37"
                        data-id="26"
                        class="item_category"
                        style="background-color: #edde53;"
                      >
                        Pase Largo
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 38"
                        data-id="27"
                        class="item_category"
                        style="background-color: #971773;"
                      >
                        1 vs 1
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 39"
                        data-id="28"
                        class="item_category"
                        style="background-color: #4a3fcb;"
                      >
                        Tiro Libre
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 40"
                        data-id="29"
                        class="item_category font-min"
                        style="background-color: #1ee1b2;"
                      >
                        Balón recuperado en cancha rival
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 41"
                        data-id="30"
                        class="item_category"
                        style="background-color: #db6a19;"
                      >
                        Saque de banda
                      </div>
                      <div
                        v-if="getOriginAwayC[2].category == 42"
                        data-id="31"
                        class="item_category"
                        style="background-color: #c6df2d;"
                      >
                        Penal
                      </div>
                    </template>
                  </template>
                </div>
                <img class="img_field" src="/images/cancha.png" width="192" />
                <template v-if="getOriginAwayC.length > 0">
                  <template v-if="getOriginAwayC[0]">
                    <img
                      v-if="getOriginAwayC[0].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[0].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayC[1]">
                    <img
                      v-if="getOriginAwayC[1].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[1].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                  <template v-if="getOriginAwayC[2]">
                    <img
                      v-if="getOriginAwayC[2].category == 32"
                      data-id="32"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 33"
                      data-id="33"
                      class="img_field_o"
                      src="/images/origenes/origen_centro_izq_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 34"
                      data-id="34"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_derecha_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 35"
                      data-id="35"
                      class="img_field_o"
                      src="/images/origenes/origen_corner_izquierda_ref.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 36"
                      data-id="36"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_filtrado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 37"
                      data-id="37"
                      class="img_field_o"
                      src="/images/origenes/origen_pase_largo.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 38"
                      data-id="38"
                      class="img_field_o"
                      src="/images/origenes/origen_1vs1.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 39"
                      data-id="39"
                      class="img_field_o"
                      src="/images/origenes/origen_tiro_libre.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 40"
                      data-id="40"
                      class="img_field_o"
                      src="/images/origenes/origen_balon_recuperado.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 41"
                      data-id="41"
                      class="img_field_o"
                      src="/images/origenes/origen_saque_banda.png"
                      width="192"
                    />
                    <img
                      v-if="getOriginAwayC[2].category == 42"
                      data-id="42"
                      class="img_field_o"
                      src="/images/origenes/origen_penal.png"
                      width="192"
                    />
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <LegendLastSeason :mobile="isMobile" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'BetFaceToFace',
  components: {
    LegendLastSeason: () => import('@/components/Sections/GolTipster-v2/General/LegendLastSeason'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
  },
  props: {
    gameData: {
      type: Object,
      default: () => ({
        seasonId: 0,
        homeTeam: null,
        awayTeam: null,
      }),
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['filterPlayingAs', 'filterGamePeriod']),
    ...mapGetters('betsGeneral', ['getOriginHomeF', 'getOriginHomeC', 'getOriginAwayF', 'getOriginAwayC']),
    isMobile() {
      return this.displayWidth < 464;
    },
  },
  watch: {
    gameData: {
      deep: true,
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue.seasonId) {
            await Promise.all([
              this.getFaceToFacePerGame({
                season_id: newValue.seasonId,
                team_id: newValue.homeTeam.team_id,
                type: 'L',
              }),
              this.getFaceToFacePerGame({
                season_id: newValue.seasonId,
                team_id: newValue.awayTeam.team_id,
                type: 'V',
              }),
            ]);
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
    filterPlayingAs() {
      this.setOriginsData();
    },
    filterGamePeriod() {
      this.setOriginsData();
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getFaceToFacePerGame', 'setOriginsPerTeam']),
    setOriginsData() {
      this.setOriginsPerTeam({ type: 'L', team: this.gameData.homeTeam.team_id });
      this.setOriginsPerTeam({ type: 'v', team: this.gameData.awayTeam.team_id });
    },
  },
};
</script>
<style scoped lang="scss">
.line-separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
}
.center-item {
  margin: 0 auto;
  display: inline-block;
  @media screen and (max-width: 463px) {
    width: 100% !important;
  }
}
.float-item-left {
  justify-content: left;
  text-align: left;
}
.paddingText-right {
  padding-right: 15px;
}
.paddingText-left {
  padding-left: 15px;
}
.float-item-right {
  justify-content: right;
  text-align: right;
}
.cont_item_category {
  width: 146px;
  height: auto;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 413px) {
    width: 129px;
  }
  @media screen and (max-width: 313px) {
    width: 99px;
  }
}
.orange {
  background-color: #f90;
}
.green {
  background-color: #70c10a;
}
.blue {
  background-color: #00a5ff;
}
.font-min {
  font-size: 9px !important;
  @media screen and (max-width: 413px) {
    font-size: 8px !important;
  }
  @media screen and (max-width: 313px) {
    font-size: 6px !important;
  }
}
.item_category {
  width: 146px;
  height: 26px;
  border-radius: 24px;

  font-family: 'Roboto-Medium';
  font-size: 11px;
  margin-top: 2px;
  font-weight: 500;

  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: -0.11px;
  text-align: center;
  color: #fff;
  @media screen and (max-width: 413px) {
    width: 129px;
    font-size: 10px;
    height: 21px;
    line-height: 21px;
  }
  @media screen and (max-width: 313px) {
    width: 99px;
    font-size: 9px;
    height: 19px;
    line-height: 19px;
  }
}
.img_field {
  position: absolute;
  left: 0px;
  top: 0px;
}
.img_field_o {
  position: absolute;
  left: 0px;
  top: 0px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.is-desktop {
  width: 100%;
  display: inline-block;
  @media screen and (max-width: 813px) {
    display: none;
  }
}
.is-movil {
  width: 100%;
  display: none;
  @media screen and (max-width: 813px) {
    display: inline-block;
  }
}
.green {
  background-color: #84c916;
}
.red {
  background-color: #ff5a44;
}
.bpr-facetoface {
  width: 98%;
  height: auto;
  margin: 0 auto;
  position: relative;
  // background: rgb(170, 255, 241);
  &__itemfields {
    width: 408px;
    height: 368px;
    // background: #132839;
    display: inline-flex;
    justify-content: space-between;
    @media screen and (max-width: 863px) {
      width: 394px;
      // background: rgb(0, 226, 177);
    }
    @media screen and (max-width: 813px) {
      width: 408px;
      height: 368px;
      // background: #132839;
      display: inline-flex;
      justify-content: space-between;
    }
    @media screen and (max-width: 413px) {
      width: 360px;
      height: auto;
      // background: brown;
      justify-content: center;
    }
    @media screen and (max-width: 363px) {
      width: 300px;
      height: auto;
      // background: rgb(40, 86, 185);
      justify-content: center;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
      height: auto;
      // background: rgb(141, 185, 40);
      justify-content: center;
    }
    &__container {
      width: 192px;
      display: inline-block;
      height: 346px;
      margin-top: 10px;
      // background: rgb(7, 92, 78);
      @media screen and (max-width: 413px) {
        width: 161px;
        // background: brown;
        margin-left: 5px;
        margin-right: 5px;
        height: auto;
      }
      &__title {
        width: 100%;
        height: 26px;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 500;
        line-height: 26px;
        font-family: 'Roboto-Medium';
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.15px;
        text-align: center;
        color: #fff;
      }
      &__field {
        width: 100%;
        height: 312px;
        margin-top: 8px;
        position: relative;
        @media screen and (max-width: 413px) {
          height: 262px;
        }
        @media screen and (max-width: 363px) {
          height: 242px;
        }
        @media screen and (max-width: 313px) {
          height: 222px;
        }
      }
    }
  }
  /* vbloque */
  &__containerfield {
    width: 100%;
    height: 368px;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 10px;
    // background: rgb(151, 108, 0);
    @media screen and (max-width: 813px) {
      justify-content: center;
    }
    @media screen and (max-width: 413px) {
      height: auto;
    }
  }
  /* vbloque */
  &__header {
    position: relative;
    width: 100%;
    height: 42px;
    margin: 0 auto;
    @media screen and (max-width: 563px) {
      height: 50px;
    }
    &__title {
      width: 515px;
      height: 42px;
      position: absolute;
      line-height: 42px;
      background-color: rgb(255, 255, 255);
      font-family: 'Roboto-Black';
      font-size: 26px;
      font-weight: 800;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.26px;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 563px) {
        width: 343px;
        line-height: 26px;
        height: 50px;
      }
      @media screen and (max-width: 363px) {
        width: 275px;
        line-height: 26px;
        height: 50px;
        font-size: 22px;
      }
    }
  }
  /* vbloque */
  /* vbloque */
  &__filterPlay {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-top: 16px;
    border-radius: 4px;
  }

  /* vbloque */
  &__table {
    // background: #132839;
    display: inline-flex;
    width: 100%;
    height: auto;
    margin-top: 26px;
    justify-content: space-between;
    &__home {
      width: 43%;
      height: auto;
      @media screen and (max-width: 1000px) {
        width: 45%;
      }
      @media screen and (max-width: 950px) {
        width: 47%;
      }
      @media screen and (max-width: 913px) {
        width: 49%;
      }
      @media screen and (max-width: 863px) {
        width: 50%;
      }
      @media screen and (max-width: 813px) {
        width: 100%;
        justify-content: center;
      }
      &__header {
        width: auto;
        height: 76px;
        @media screen and (max-width: 813px) {
          display: inline-flex;
          justify-content: center;
        }
      }
      &__catcontainer {
        width: 100%;
        height: auto;
      }
    }
    &__away {
      width: 43%;
      height: auto;
      @media screen and (max-width: 1000px) {
        width: 45%;
      }
      @media screen and (max-width: 950px) {
        width: 47%;
      }
      @media screen and (max-width: 913px) {
        width: 49%;
      }
      @media screen and (max-width: 863px) {
        width: 50%;
      }
      @media screen and (max-width: 613px) {
        width: 50%;
      }
      &__header {
        width: 100%;
        height: 76px;
      }
      &__catcontainer {
        width: 100%;
        height: auto;
      }
    }
  }
  /* vbloque */
  /* vbloque */
  &__itemheadertable {
    width: 99%;
    height: 76px;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 813px) {
      justify-content: center;
      // background: #9e83ff;
      width: auto;
    }
    &__shield {
      width: 50px;
      height: 56px;
      margin-top: 10px;
      position: relative;
      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 3px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__name {
      width: auto;
      height: 56px;
      line-height: 56px;
      margin-top: 10px;
      font-size: 26px;
      font-family: 'Roboto-Black';
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.26px;
      color: #132839;
      @media screen and (max-width: 813px) {
        width: auto;
        // background: gold;
      }
    }
  }
  /* vbloque */
}
</style>
