import { render, staticRenderFns } from "./BetFaceToFace.vue?vue&type=template&id=75ddc094&scoped=true&"
import script from "./BetFaceToFace.vue?vue&type=script&lang=js&"
export * from "./BetFaceToFace.vue?vue&type=script&lang=js&"
import style0 from "./BetFaceToFace.vue?vue&type=style&index=0&id=75ddc094&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ddc094",
  null
  
)

export default component.exports